import React from 'react'
import Layout from '../../components/Layout'
import BlogRoll from '../../components/BlogRoll'

export default class BlogIndexPage extends React.Component {
	render() {
		return (
			<Layout>
				<div
					className="full-width-image-container margin-top-0"
					style={{
						backgroundImage: `url('/img/buildings.jpg')`,
					}}
				>
					<h1 className="headline has-text-weight-bold is-size-1">
						Latest Stories
					</h1>
				</div>
				<section className="section">
					<div className="container">
						<div className="content">
							<BlogRoll/>
						</div>
					</div>
				</section>
			</Layout>
		)
	}
}
